import { Col, Row, Section } from 'components/grid'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Theme } from 'templates/theme'

interface Props {
	data: Theme
}

const TemplateHeader = ({ data }: Props) => {
	return (
		<Section>
			<Row className="mt-10 items-center">
				<Col width="w-full md:w-2/3 md:pl-12">
					<H1 className="mb-3 text-center sm:text-left">
						{data.logo ? <img src={data.logo.file.url} className="mx-auto" alt={`${data.name} Theme Logo`} style={{ maxHeight: 150 }} /> : data.name}
					</H1>
					<P className="mb-3">{data.description ? data.description.description : 'No description for this theme.'}</P>
					<span className="mb-1 text-lg font-semibold text-ab-100">
						Available for Grades {data.gradeLevel}{' '}
						{data.schoolPaceConnectGrades && (
							<span>
								and in SchoolPace Connect<sup>®</sup> for Grade{' '}
								{data.schoolPaceConnectGrades.map((grade, i, grades) => {
									if (grades.length > 2) {
										return <span key={i}>{i !== grades.length - 1 ? `${grade}, ` : `and ${grade}`}</span>
									} else if (grades.length === 2) {
										return <span key={i}>{i === 0 ? `${grade} and ` : grade}</span>
									} else {
										return <span key={i}>{grade}</span>
									}
								})}
							</span>
						)}
					</span>
				</Col>
				<Col width="w-full md:w-1/3" className="flex justify-center">
					{data.frameworkCover && (
						<div>
							<GatsbyImage image={data.frameworkCover.gatsbyImageData} alt={`${data.name} Framework Cover`} />
							<p className="text-center text-xs text-gray-500">representative grade sample shown</p>
						</div>
					)}
				</Col>
			</Row>
		</Section>
	)
}

export default TemplateHeader
